.banner {
	width: 100%;
	height: 100vh;
	position: relative;
}

.slider {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.chapter {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	object-fit: cover;
	transition: opacity 0.5s ease-in-out;
}

.active {
	opacity: 1;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

.title {
	text-align: center;
	color: white;
	user-select: none;
}

.title h1 {
	font-size: 6em;
	font-weight: 200;
}

.title h2 {
	font-size: 3em;
	font-weight: 400;
}

.title p {
	font-size: 1.5rem;
	font-weight: 400;
	margin-top: 1em;
}

.arrowContainer {
	height: 100%;
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	cursor: pointer;
	z-index: 3;
	padding: 0.8em;
	transition: transform 0.3s ease;
	font-size: 3em;
}

.arrowContainer:hover {
	transform: scale(1.2);
}

.dots {
	position: absolute;
	bottom: 1em;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 1em;
	z-index: 3;
	padding: 0.8em;
	border-radius: 50px;
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
}

.dot {
	width: 0.6rem;
	height: 0.6rem;
	border-radius: 50px;
	background: rgba(255, 255, 255, 0.4);
	cursor: pointer;
	transition: background 0.3s ease;
}

.activeDot {
	background: white;
	box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

.pillarsContainer {
	display: flex;
	justify-content: space-evenly;
	width: 90%;
	margin: 2em auto;
}

.pillar {
	flex: 1;
	text-align: center;
	padding: 2rem;
}

.icon {
	font-size: 4.5rem;
	color: var(--red);
	margin-bottom: 1.5rem;
}

.pillar h2 {
	color: var(--red);
	font-size: 34px;
	font-weight: 400;
}

.underline {
	height: 3px;
	width: 150px;
	background-color: var(--red);
	margin: 1.5em auto;
}

.pillar p {
	font-size: 18px;
	line-height: 1.6;
	color: #555;
}

@media (max-width: 1150px) {
	.pillarsContainer {
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 950px) {
	.title h1 {
		font-size: 2em;
		padding-bottom: 0.5em;
	}
	
	.title h2 {
		font-size: 1.25em;
	}
	
	.title p {
		font-size: 1rem;
	}
	
	.arrowContainer {
		font-size: 2em;
	}

	.arrow {
		display: none;
	}
	
	.icon {
		font-size: 3.5rem;
		margin-bottom: 1rem;
	}

	.pillar {
		padding: 1em;
	}

	.underline {
		margin: 1em auto;
	}

	.pillar p {
		font-size: 16px;
	}
	
	.pillar h2 {
		font-size: 26px;
	}
}