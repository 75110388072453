:root {
  --red: #7D2121; /*reminder to manually update this in /public/index.html*/
  --gold: #B9996C;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.header {
  z-index: 1000;
  width: 100%;
}

.body {
  width: 100%;
  padding-top: 8em;
}

@media screen and (max-width: 850px) {
  .body {
    padding-top: 6em;
  }
}

.footer {
  margin-top: auto;
}