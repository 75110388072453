.brotherProfile {
    width: 10em;
}

.brotherCard {
    height: 14em;
    perspective: 1500px;
    transition: transform 0.25s ease;
    border-radius: 5px;
    will-change: transform;
}

.brotherCard:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.flip {
    height: 100%;
    width: 100%;
    transition: transform 0.5s ease;
    transform-style: preserve-3d;
    will-change: transform;
}

.flipCard {
    transform: rotateY(181deg); /* for some reason it flips the other way if the value is exactly 180 so leave it at 181*/
}

.front, .back {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    position: absolute;
    backface-visibility: hidden;
    pointer-events: none;
    background-color: #f5f5f5;
    transform: translateZ(0);
    will-change: transform;
}

.front{
    border: 1px solid black;
    border-radius: 5px;
}

.back{
    transform: rotateY(180deg);
    border: 1px solid lightgray;
}

.headshot {
    height: 100%;
    width: 100%;
    pointer-events: none;
    object-fit: cover;
    border-radius: 4px;
}

.details {
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.details h2 {
    font-size: 20px;
    font-weight: 200;
    margin: 5% 0 0 5%;
    pointer-events: none;
    color: var(--red);
}

.details h3 {
    font-size: 18px;
    font-weight: 200;
    margin: 0 5%;
    pointer-events: none;
    border: none;
}

.info {
    width: 100%;
    height: fit-content;
}

.info h1 {
    width: 100%;
    margin-top: 10px;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
}

@media screen and (max-width: 750px) {
    
    .brotherProfile {
        width: 9em;
    }
    
    .brotherCard {
        height: 12em;
    }

    .details h2 {
        font-size: 18px;
    }
    
    .details h3 {
        font-size: 16px;
    }

    .brotherCard:hover {
        transform: none;
    }
}