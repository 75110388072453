.rush {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4em 0;
}

.section {
	padding-bottom: 1em;
	color: var(--gold);
	text-align: left;
	font-size: 30px;
	font-weight: 400;
	margin: 0 auto;
	width: 85%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.section::after {
	content: '';
	height: 2px;
	background-color: var(--gold);
	flex-grow: 1;
}

.fullTitle {
	display: inline;
}

.shortTitle {
	display: none;
}

.timelineContainer {
	width: 80%;
	display: flex;
	justify-content: center;
	margin: 2em 0;
}

.timeline {
	display: flex;
	flex-direction: column;
	height: fit-content;
}

.event {
	display: flex;
}

.left {
	width: 200px;
	padding-right: 15px;
	text-align: right;
	position: relative;
	color: var(--gold);
	flex-shrink: 0;
}

.right {
	margin-left: 15px;
	position: relative;
	flex: 1;
	min-width: 0;
	word-wrap: break-word;
}

.date {
	padding-bottom: 8px;
	font-size: 18px;
}

.time, .seperator, .location {
	display: inline;
	font-size: 16px;
}

.name {
	color: var(--red);
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.description {
	color: #555;
	font-size: 18px;
	margin-bottom: 5px;
}

.attire {
	color: #555;
	margin-bottom: 3em;
	font-size: 16px;
	font-style: italic;
}

.left::after {
	position: absolute;
	width: 2px;
	height: 100%;
	background-color: var(--gold);
	content: "";
	box-sizing: content-box;
	right: 0;
	top: 10px;
}

.right::before {
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: var(--red);
	content: '';
	border-radius: 50%;
	left: -21px;
	top: 5px;
	border: 2px solid var(--gold);
}

.event:last-child .left::after {
	display: none;
}

.event:last-child .right .attire {
	margin-bottom: 0;
}

.buttonContainer {
	margin: 1em 0 3em 0;
	display: flex;
	justify-content: center;
	gap: 3em;
}

.button {
	display: inline-block;
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 20px;
	background-color: var(--red);
	cursor: pointer;
	padding: 15px 20px;
	border-radius: 50px;
	transition: all 0.3s ease;
	border: 2px solid var(--red);
	user-select: none;
}

.button:hover {
	background-color: white;
	color: var(--red);
}

.faqsContainer {
	width: 75%;
	margin: 0 auto;
}

.faq {
	overflow: hidden;
}

.faqToggle {
	display: none;
}

.question {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5em 2em;
	cursor: pointer;
	border-radius: 8px;
	transition: background-color 0.3s ease;
	background-color: white;
}

.questionText {
	flex: 1;
	padding-right: 1.5em;
}

.question h3 {
	color: var(--red);
	font-size: 20px;
	margin: 0;
}

.question:hover {
	background-color: #f5f5f5;
}

.faqToggle:checked ~ .question {
	background-color: #f5f5f5;
}

.icons {
	display: flex;
	font-size: 18px;
	color: var(--red);
}

.icons .close {
	display: none;
}

.faqToggle:checked ~ .question .icons .open {
	display: none;
}

.faqToggle:checked ~ .question .icons .close {
	display: flex;
}

.answer {
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 0;
	overflow: hidden;
	font-size: 18px;
	transition: max-height 0.3s ease, padding 0.3s ease;
}

.faqToggle:checked ~ .answer {
	max-height: 500px;
	padding: 1.5em 0;
}

.answer p {
	margin: 0;
	color: #555;
	line-height: 1.6;
	width: 85%;
	text-align: left;
}

.list {
	padding: 1em 0 0 3em;
}

.list li {
	color: #555;
	font-size: 18px;
	line-height: 1.6;
	margin-bottom: 0.5em;
}

.list ul {
	padding: 0.5em 0 0 1.5em;
}

.list li:last-child {
	margin-bottom: 0;
}

@media screen and (max-width: 700px) {
    .section {
		padding-bottom: 1em;
		font-size: 28px;
	}

	.timelineContainer {
		width: 80%;
		display: flex;
		justify-content: center;
		margin: 1em 0 3em 0;
	}
	
	.left {
		width: 120px;
	}

	.time, .location {
		display: block;
		font-size: 14px;
		padding-top: 5px;
	}

	.seperator {
		display: none;
	}

	.date {
		padding-top: 2px;
		padding-bottom: 0px;
		font-size: 16px;
	}
	
	.day {
		display: none;
	}

	.name {
		font-size: 18px;
	}
	
	.description {
		font-size: 16px;
	}
	
	.attire {
		font-size: 14px;
	}

	.fullTitle {
		display: none;
	}

	.shortTitle {
		display: inline;
	}

	.buttonContainer {
		margin-bottom: 2em;
	}

	.button {
		font-size: 16px;
		transition: none;
	}
	
	.button:hover {
		background-color: var(--red);
		color: white;
	}

	.question h3 {
		font-size: 16px;
	}

	.question {
		padding: 1em;
		transition: none;
	}
	
	.questionText {
		padding-right: 1em;
	}
	
	.question:hover {
		background-color: white;
	}

	.faqToggle:checked ~ .answer {
		padding: 1em 0;
	}

	.list li {
		font-size: 16px;
	}
}