.header {
    width: 100%;
    background: var(--red);
    height: 8em;
    color: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}

.main {
    margin-left: 6em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navLogo {
    height: 4rem;
    width: auto;
}

.nav {
    margin-right: 6em;
    display: flex;
    align-items: center;
    gap: 3em;
}

.link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    font-size: 18px;
}

.link:hover {
    transform: scale(1.1);
}

.active {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.toggle, .icons {
    display: none;
}

@media screen and (max-width: 850px) {
    .header {
        height: 6em;
    }

    .main {
        margin-left: 1em;
    }

    .navLogo {
        height: 3.5em;
    }

    .nav {
        position: absolute;
        display: block;
        left: 0;
        top: 100%;
        width: 100%;
        height: 0;
        overflow: hidden;
        font-size: 18px;
        transition: height 0.3s ease;
        background: var(--red);
    }

    .link {
        display: block;
        width: fit-content;
        padding: 7px;
        margin: 15px auto;
    }

    .icons {
        display: flex;
        margin: auto 5% auto auto;
        padding: 5px;
        border-radius: 5px;
        font-size: 30px;
        cursor: pointer;
    }

    .icons .close {
        display: none;
    }
    
    .toggle:checked ~ .icons .open {
        display: none;
    }
    
    .toggle:checked ~ .icons .close {
        display: flex;
    }

    .toggle:checked ~ .nav {
        height: 13em;
    }
}