.about {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4em 0;
}

.section {
	padding-bottom: 1em;
	color: var(--gold);
	text-align: left;
	font-size: 30px;
	font-weight: 400;
	margin: 0 auto;
	width: 85%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.section::after {
	content: '';
	height: 2px;
	background-color: var(--gold);
	flex-grow: 1;
}

.textContainer {
	width: 80%;
	margin: 0 auto 3em auto;
	padding: 2em;
	background-color: #f5f5f5;
	border-radius: 8px;
  border: 1px solid lightgray;
}

.text {
	font-size: 18px;
	line-height: 1.6;
	color: #555;
	text-align: justify;
}

.breakdownContainer {
	display: flex;
	width: 80%;
	justify-content: space-evenly;
  user-select: none;
	margin-bottom: 2em;
}

.chartTitle {
  color: #555 ;
	font-size: 26px;
  font-weight: 400;
  text-align: center;
	padding-top: 1em;
}

.chartContainer {
  background-color: #f5f5f5;
  border: 1px solid lightgray;
  border-radius: 1em;
  padding: 1em;
}

.tooltip {
	background-color: rgba(0, 0, 0, 0.8);
	padding: 8px 12px;
	border: none;
	border-radius: 4px;
	color: white;
	font-size: 14px;
}

.legend {
	list-style: none;
	min-width: 120px;
}

.legendItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 5px 0;
}

.dot {
	width: 10px;
	height: 10px;
	margin-right: 8px;
	flex-shrink: 0;
}

.legendText {
	color: #555;
}

@media screen and (max-width: 1150px) {
	.breakdownContainer {
		flex-direction: column;
		align-items: center;
		gap: 3em;
	}

	.chartContainer {
		max-width: 100%;
		overflow-x: auto;
		padding: 0.75em;
	}

	.chartTitle {
		font-size: 22px;
	}

	.legendItem {
		margin: 0;
	}
}