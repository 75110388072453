.brothers {
    padding-bottom: 4em;
}

.categories {
    display: inline-flex;
    margin-top: 4em;
    border: 2px solid var(--red);
    border-radius: 50px;
    padding: 5px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    user-select: none;
}

.category {
    font-size: 18px;
    font-weight: 400;
    color: var(--red);
    cursor: pointer;
    padding: 0.5em 1.5em;
    position: relative;
    transition: all 0.3s ease;
    border-radius: 50px;
    user-select: none;
}

.selector {
    position: absolute;
    height: calc(100% - 10px);
    background-color: var(--red);
    border-radius: 50px;
    transition: all 0.3s ease;
    pointer-events: none;
}

.active {
    color: white;
}

.Title {
    padding: 2em 0 1em 0;
    color: var(--gold);
    text-align: left;
    font-size: 30px;
    font-weight: 400;
    margin: 0 auto;
    width: 85%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.Title::after {
    content: '';
    height: 2px;
    background-color: var(--gold);
    flex-grow: 1;
}

.Members {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 2em;
}

.boardMember {
    width: 10em;
}

.role {
    width: 10em;
    width: 100%;
    margin-top: 10px;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1600px) {
    .Members {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1300px) {
    .Members {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1100px) {
    .Members {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 750px) {
    .Members {
        grid-template-columns: 1fr 1fr;
    }

    .category {
        font-size: 16px;
        padding: 0.4em 1.2em;
    }

    .Title {
		font-size: 28px;
	}
}