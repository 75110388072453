.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--red);
    color: white;
    padding: 1em;
}

.contents {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0.5em; */
    user-select: none;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding-top: 10px;
}

.buttons a {
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.letters {
    height: 1.5em;
    margin-top: 4px;
}

.insta {
    font-size: 23px;

}

.mail {
    font-size: 28px;

}

.credits {
    font-size: 18px;
    font-weight: 400;
    padding: 10px 0;
    border-radius: 0.5em;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
    transition: transform 0.3s ease;
}

.credits:hover {
    transform: scale(1.1);
}

.tooltip {
    position: relative;
}

.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    transform: translateX(-50%) translateY(10px);
}

.tooltip:hover::before {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-5px);
}